/* eslint-disable max-len */
import React from 'react'
import { Link } from 'gatsby'
import ChevronRightIcon from '@heroicons/react/solid/ChevronRightIcon'
import Button from '../Button'
import RenderIf from '../RenderIf'
import { Paragraph__Marketing_Hero } from '../../types/generated'
import { urlSafeBase64 } from '../../utils'

interface Props {
  hero: Paragraph__Marketing_Hero
}

const MarketingHero = ({ hero }: Props) => {
  const image =
    hero?.relationships?.field_image?.relationships?.field_media_image?.uri?.url ??
    '/sites/default/files/2021-08/pexels-lukas-296301.jpg'

  return (
    <div className="py-6 md:pt-12 lg:pt-0 lg:py-0 lg:px-8 bg-sdusd-blue lg:overflow-hidden rounded-2xl">
      <div className="mx-auto max-w-7xl lg:px-8">
        <div className="lg:grid lg:grid-cols-2 lg:gap-8">
          <div className="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 sm:text-center lg:px-0 lg:text-left lg:flex lg:items-center">
            <div className="lg:py-24">
              <RenderIf condition={!!hero?.field_link?.uri || !!hero?.field_link?.uri_alias}>
                <Link
                  to={
                    hero?.field_link?.uri_alias ??
                    hero?.field_link?.uri?.replace('internal:', '') ??
                    ''
                  }
                  className="hidden sm:inline-flex items-center text-white bg-purple-700 rounded-full p-1 pr-2 sm:text-base lg:text-sm xl:text-base hover:text-gray-200"
                >
                  <span className="ml-4 text-base">{hero?.field_link?.title}</span>
                  <ChevronRightIcon className="ml-2 w-5 h-5 text-white" aria-hidden="true" />
                </Link>
              </RenderIf>
              <h1 className="sm:mt-4 font-display text-4xl tracking-tight font-extrabold text-white sm:mt-5 sm:text-6xl lg:mt-6 xl:text-6xl">
                <span className="block">{hero?.field_title}</span>
              </h1>
              <p className="mt-3 text-base text-gray-300 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
                {hero?.field_subtitle}
              </p>
              <div className="mt-10 sm:mt-12">
                <div className="sm:flex sm:justify-center lg:justify-start">
                  <div className="mt-3 sm:mt-0">
                    <RenderIf
                      condition={
                        !!hero?.field_button_link?.uri || !!hero?.field_button_link?.uri_alias
                      }
                    >
                      <Link
                        to={
                          hero?.field_button_link?.uri_alias ??
                          hero?.field_button_link?.uri?.replace('internal:', '') ??
                          ''
                        }
                      >
                        <Button>{hero?.field_button_link?.title}</Button>
                      </Link>
                    </RenderIf>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="lg:relative">
            <div className="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 lg:max-w-none lg:px-0">
              <img
                className="hidden sm:block"
                src={`https://discover-admin.sandiegounified.org/image-transformation-api/${urlSafeBase64(
                  image.replace('/sites/default/files/', 'public://'),
                )}/c_scale,w_600,h_600|c_convert,extension_webp`}
                alt={hero?.relationships?.field_image?.thumbnail?.alt ?? ''}
                loading="lazy"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MarketingHero
