import React from 'react'
import { graphql } from 'gatsby'
import MarketingHero from '../../MarketingHero'
import { Paragraph__Marketing_Hero } from '../../../types/generated'

export const ParagraphMarketingHeroFields = graphql`
  fragment ParagraphMarketingHeroFields on paragraph__marketing_hero {
    __typename
    id
    field_title
    field_subtitle
    field_link {
      uri
      title
      uri_alias
    }
    field_button_link {
      uri
      title
      uri_alias
    }
    relationships {
      field_image {
        thumbnail {
          alt
        }
        gatsbyImageData(width: 1200, aspectRatio: 2)
        relationships {
          field_media_image {
            uri {
              url
              value
            }
          }
        }
      }
    }
  }
`

const ParagraphMarketingHero = (props: Paragraph__Marketing_Hero) => (
  <section>
    <div className="max-w-7xl mx-auto">
      <MarketingHero hero={props} />
    </div>
  </section>
)

export default ParagraphMarketingHero
